// Patches daterangepicker-dayjs to not require mutable dayjs to navigate dates.
// Source: https://github.com/iamkumaran/daterangepicker-dayjs/blob/master/daterangepicker.js#L1227-L1249

import daterangepicker from 'daterangepicker-dayjs/daterangepicker.js';

daterangepicker.prototype.clickNext = function (e) {
  var cal = $(e.target).parents('.drp-calendar');
  if (cal.hasClass('left')) {
    this.leftCalendar.month = this.leftCalendar.month.clone().add(1, 'month');
  } else {
    this.rightCalendar.month = this.rightCalendar.month.clone().add(1, 'month');
    if (this.linkedCalendars)
      this.leftCalendar.month = this.leftCalendar.month.clone().add(1, 'month');
  }
  this.updateCalendars();
}

daterangepicker.prototype.clickPrev = function (e) {
  var cal = $(e.target).parents('.drp-calendar');
  if (cal.hasClass('left')) {
    this.leftCalendar.month = this.leftCalendar.month.clone().subtract(1, 'month');
    if (this.linkedCalendars)
      this.rightCalendar.month = this.rightCalendar.month.clone().subtract(1, 'month');;
  } else {
    this.rightCalendar.month = this.rightCalendar.month.clone().subtract(1, 'month');;
  }
  this.updateCalendars();
}
