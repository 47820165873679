// Do not import badMutable plugin - Makes dayjs mutable and breaks expected behavior
// e.g day.add(1, 'days') -> would mutate day variable

// General use
import relativeTime from 'dayjs/plugin/relativeTime'    
import advancedFormat from 'dayjs/plugin/advancedFormat'
import weekday from 'dayjs/plugin/weekday'

// daterangepicker-dayjs dependencies
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isoWeek from "dayjs/plugin/isoWeek";
import arraySupport from "dayjs/plugin/arraySupport";

// Range custom plugin
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from 'dayjs/plugin/isBetween'
import range from './custom_plugins/range'

// import badMutable from "dayjs/plugin/badMutable"; Do not import this plugin, makes DayJS mutable and breaks expected behavior

// Load plugins
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(isoWeek);
dayjs.extend(arraySupport);
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)
dayjs.extend(range)

// Monkey-patch for daterangepicker to not require mutable dates
import './custom_plugins/date_range_picker_immutable_patch'
