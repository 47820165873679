class DayjsRangePlugin {
  constructor(startDate, endDate) {
    if (typeof startDate === 'string') { startDate = dayjs(startDate) }
    if (typeof endDate === 'string') { endDate = dayjs(endDate) }

    [startDate, endDate] = startDate.isAfter(endDate) ? [endDate, startDate] : [startDate, endDate]
    this.startDate = startDate
    this.endDate = endDate
    this.start = this.startDate
    this.end = this.endDate
  }

  diff(unit = 'day', args = { exclusive: false }) {
    const diff = this.endDate.diff(this.startDate, unit)
    return args.exclusive ? diff : diff + 1
  }

  by(unit = 'day') {
    return Array.from({ length: this.diff() }, (_v, i) => this.startDate.add(i, unit))
  }

  contains(date, args = { exclusive: false }) {
    if (args.exclusive) {
      return date.isBetween(this.startDate, this.endDate, 'day', '()')
    }
    return date.isBetween(this.startDate, this.endDate, 'day', '[]')
  }

  overlaps(other, args = { adjacent: false }) {
    const doesOverlap = this.startDate < other.endDate && this.endDate > other.startDate;

    if (doesOverlap) return doesOverlap
    if (args.adjacent) return this.adjacent(other)
  }

  adjacent(other) {
    const sameStartEnd = this.startDate.isSame(other.endDate)
    const sameEndStart = this.endDate.isSame(other.startDate)

    return (sameStartEnd && (other.startDate.valueOf() <= this.start.valueOf())) || (sameEndStart && (other.end.valueOf() >= this.end.valueOf()))
  }
}

function newRangePluginInstance(startDate, endDate) {
  return new DayjsRangePlugin(startDate, endDate)
}

export default (option, dayjsClass, dayjsFactory) => {
  dayjsFactory.range = newRangePluginInstance
}